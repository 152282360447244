import { render, staticRenderFns } from "./OrgFooterNav.vue?vue&type=template&id=0d1fe359&scoped=true"
import script from "./OrgFooterNav.vue?vue&type=script&lang=ts"
export * from "./OrgFooterNav.vue?vue&type=script&lang=ts"
import style0 from "./OrgFooterNav.vue?vue&type=style&index=0&id=0d1fe359&prod&lang=sass&scoped=true&module=true"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "0d1fe359",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VBottomNavigation,VBtn,VIcon})
