import { mutationTree } from 'typed-vuex'

const defaultState = () => {
  return {
    content: '',
  }
}

export const state = () => defaultState()

export const mutations = mutationTree(state, {
  showSuccessMessage(state, payload: { content: string }) {
    state.content = payload.content
  },
  showErrorMessage(state, payload) {
    state.content = payload.content
  },
})

export type RootState = ReturnType<typeof state>
