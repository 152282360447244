import { getAccessorType } from 'typed-vuex'
import * as error from '~/store/error'
import * as session from '~/store/session'
import * as auth from '~/store/auth'
import * as snackbar from '~/store/snackbar'
import * as address from '~/store/address'
import * as user from '~/store/user'
import * as vehicle from '~/store/vehicle'
import * as tire from '~/store/tire'
import * as purchase from '~/store/purchase'
import * as shop from '~/store/shop'
import * as quotation from '~/store/quotation'
import * as reservation from '~/store/reservation'
import * as receipt from '~/store/receipt'
import * as _receipt from '~/store/_receipt'
import * as _quotation from '~/store/_quotation'
import * as _vehiclesRegistration from '~/store/_vehiclesRegistration'
import * as _reservation from '~/store/_reservation'
import * as log from '~/store/log'

// これらは型推論に必要のため、空でも定義しておく
export const state = () => ({})
export const getters = {}
export const mutations = {}
export const actions = {}

const modules = {
  error,
  session,
  auth,
  snackbar,
  address,
  user,
  vehicle,
  tire,
  purchase,
  shop,
  quotation,
  receipt,
  log,
  reservation,
  // Page
  _receipt,
  _quotation,
  _vehiclesRegistration,
  _reservation,
}

export const storeKeys = Object.keys(modules)
export const persistedKeys =
  process.env.STAGE === 'localhost'
    ? // 開発効率向上のため、ローカル環境では一部のステートを永続化する
      ['session', 'auth', '_receipt', '_quotation', '_reservation']
    : ['session', 'auth']

export const accessorType = getAccessorType({
  state,
  getters,
  mutations,
  actions,
  modules,
})
