// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_Kug\\+b[data-v-0d1fe359]{color:rgba(0,0,0,.871)}.text-white_QlCIo[data-v-0d1fe359]{color:#fff}.text-red_HbePn[data-v-0d1fe359]{color:#c43}.text-blue_j8-qK[data-v-0d1fe359]{color:#4587c1}.color-red_iQhGr[data-v-0d1fe359]{background:#c43}.color-blue-grey_7c8Ge[data-v-0d1fe359]{background:#54617a}.color-white_n6wvD[data-v-0d1fe359]{background:#fff}.pa-1-5_YQESV[data-v-0d1fe359]{padding:6px}.mt-20_YTA4f[data-v-0d1fe359]{margin-top:80px}.mt-24_dgz\\+S[data-v-0d1fe359]{margin-top:96px}p[data-v-0d1fe359]{margin:0;padding:0}a[data-v-0d1fe359]{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_V3\\+DB fieldset[data-v-0d1fe359],.v-text-field--outlined_V3\\+DB.v-input--is-focused_ADnhg fieldset[data-v-0d1fe359]{border-width:0}.v-text-field--outlined_V3\\+DB.v-input--has-state_ixU1A fieldset[data-v-0d1fe359]{border:2px solid red!important}.v-input__slot_Q4eBn[data-v-0d1fe359]{min-height:auto!important;padding:0 12px!important}.v-input_L-ILy[data-v-0d1fe359]{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_2EYKc[data-v-0d1fe359]{margin-top:4px!important}.v-slide-group__next_a8tAb[data-v-0d1fe359],.v-slide-group__prev_1010e[data-v-0d1fe359]{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_ZUrXT[data-v-0d1fe359]{min-height:40px!important}.v-btn_\\+Ah\\+r[data-v-0d1fe359]{min-width:auto!important}.v-tab_jf5qI[data-v-0d1fe359]{padding:0 12px!important}.v-item-group_48Lbd.v-bottom-navigation_AvhcT .v-btn_\\+Ah\\+r.v-size--default_hp8cW[data-v-0d1fe359]{height:inherit}.theme--light_KoQZe.v-bottom-navigation_AvhcT[data-v-0d1fe359]{background-color:#eee!important}.v-bottom-navigation_AvhcT[data-v-0d1fe359]{height:auto!important}.v-bottom-navigation_AvhcT .v-btn_\\+Ah\\+r[data-v-0d1fe359]{margin-bottom:env(safe-area-inset-bottom)}.button_B5iyK[data-v-0d1fe359]{padding:12px 12px 34px!important}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_Kug+b",
	"text-white": "text-white_QlCIo",
	"text-red": "text-red_HbePn",
	"text-blue": "text-blue_j8-qK",
	"color-red": "color-red_iQhGr",
	"color-blue-grey": "color-blue-grey_7c8Ge",
	"color-white": "color-white_n6wvD",
	"pa-1-5": "pa-1-5_YQESV",
	"mt-20": "mt-20_YTA4f",
	"mt-24": "mt-24_dgz+S",
	"v-text-field--outlined": "v-text-field--outlined_V3+DB",
	"v-input--is-focused": "v-input--is-focused_ADnhg",
	"v-input--has-state": "v-input--has-state_ixU1A",
	"v-input__slot": "v-input__slot_Q4eBn",
	"v-input": "v-input_L-ILy",
	"v-input__append-inner": "v-input__append-inner_2EYKc",
	"v-slide-group__next": "v-slide-group__next_a8tAb",
	"v-slide-group__prev": "v-slide-group__prev_1010e",
	"v-expansion-panel-header": "v-expansion-panel-header_ZUrXT",
	"v-btn": "v-btn_+Ah+r",
	"v-tab": "v-tab_jf5qI",
	"v-item-group": "v-item-group_48Lbd",
	"v-bottom-navigation": "v-bottom-navigation_AvhcT",
	"v-size--default": "v-size--default_hp8cW",
	"theme--light": "theme--light_KoQZe",
	"button": "button_B5iyK"
};
module.exports = ___CSS_LOADER_EXPORT___;
