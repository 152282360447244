// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_FaMw\\+{color:rgba(0,0,0,.871)}.text-white_ZJukO{color:#fff}.text-red_4Vf6W{color:#c43}.text-blue_cx8eV{color:#4587c1}.color-red_Nmz5F{background:#c43}.color-blue-grey_IXeQm{background:#54617a}.color-white_p8fr2{background:#fff}.pa-1-5_XFiWT{padding:6px}.mt-20_aK\\+hq{margin-top:80px}.mt-24_p\\+9Tc{margin-top:96px}p{margin:0;padding:0}a{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_vuQVe fieldset,.v-text-field--outlined_vuQVe.v-input--is-focused_Ytl8m fieldset{border-width:0}.v-text-field--outlined_vuQVe.v-input--has-state_A6Qpe fieldset{border:2px solid red!important}.v-input__slot_rT\\+wy{min-height:auto!important;padding:0 12px!important}.v-input_ILlTf{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_3kDXH{margin-top:4px!important}.v-slide-group__next_Bqye8,.v-slide-group__prev_SI7Gi{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_6t59S{min-height:40px!important}.v-btn_BC8Bw{min-width:auto!important}.v-tab_QEXmu{padding:0 12px!important}.v-item-group_2bssv.v-bottom-navigation_i7gmw .v-btn_BC8Bw.v-size--default_t-0Gt{height:inherit}.theme--light_dXjid.v-bottom-navigation_i7gmw{background-color:#eee!important}.v-bottom-navigation_i7gmw{height:auto!important}.v-bottom-navigation_i7gmw .v-btn_BC8Bw{margin-bottom:env(safe-area-inset-bottom)}h1{font-size:20px}.bl_error_cSbz7{text-align:center}.bl_error_btn_O8KO5{margin:96px 24px 0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_FaMw+",
	"text-white": "text-white_ZJukO",
	"text-red": "text-red_4Vf6W",
	"text-blue": "text-blue_cx8eV",
	"color-red": "color-red_Nmz5F",
	"color-blue-grey": "color-blue-grey_IXeQm",
	"color-white": "color-white_p8fr2",
	"pa-1-5": "pa-1-5_XFiWT",
	"mt-20": "mt-20_aK+hq",
	"mt-24": "mt-24_p+9Tc",
	"v-text-field--outlined": "v-text-field--outlined_vuQVe",
	"v-input--is-focused": "v-input--is-focused_Ytl8m",
	"v-input--has-state": "v-input--has-state_A6Qpe",
	"v-input__slot": "v-input__slot_rT+wy",
	"v-input": "v-input_ILlTf",
	"v-input__append-inner": "v-input__append-inner_3kDXH",
	"v-slide-group__next": "v-slide-group__next_Bqye8",
	"v-slide-group__prev": "v-slide-group__prev_SI7Gi",
	"v-expansion-panel-header": "v-expansion-panel-header_6t59S",
	"v-btn": "v-btn_BC8Bw",
	"v-tab": "v-tab_QEXmu",
	"v-item-group": "v-item-group_2bssv",
	"v-bottom-navigation": "v-bottom-navigation_i7gmw",
	"v-size--default": "v-size--default_t-0Gt",
	"theme--light": "theme--light_dXjid",
	"bl_error": "bl_error_cSbz7",
	"bl_error_btn": "bl_error_btn_O8KO5"
};
module.exports = ___CSS_LOADER_EXPORT___;
