export const AtomBtnDelete = () => import('../../src/components/AtomBtnDelete.vue' /* webpackChunkName: "components/atom-btn-delete" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnFormSelect = () => import('../../src/components/AtomBtnFormSelect.vue' /* webpackChunkName: "components/atom-btn-form-select" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnOutline = () => import('../../src/components/AtomBtnOutline.vue' /* webpackChunkName: "components/atom-btn-outline" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnRound = () => import('../../src/components/AtomBtnRound.vue' /* webpackChunkName: "components/atom-btn-round" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnRoundS = () => import('../../src/components/AtomBtnRoundS.vue' /* webpackChunkName: "components/atom-btn-round-s" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnSquareS = () => import('../../src/components/AtomBtnSquareS.vue' /* webpackChunkName: "components/atom-btn-square-s" */).then(c => wrapFunctional(c.default || c))
export const AtomBtnSquareXL = () => import('../../src/components/AtomBtnSquareXL.vue' /* webpackChunkName: "components/atom-btn-square-x-l" */).then(c => wrapFunctional(c.default || c))
export const AtomErrorDescription = () => import('../../src/components/AtomErrorDescription.vue' /* webpackChunkName: "components/atom-error-description" */).then(c => wrapFunctional(c.default || c))
export const AtomErrorTitle = () => import('../../src/components/AtomErrorTitle.vue' /* webpackChunkName: "components/atom-error-title" */).then(c => wrapFunctional(c.default || c))
export const AtomIconQrRead = () => import('../../src/components/AtomIconQrRead.vue' /* webpackChunkName: "components/atom-icon-qr-read" */).then(c => wrapFunctional(c.default || c))
export const AtomListRowDetails = () => import('../../src/components/AtomListRowDetails.vue' /* webpackChunkName: "components/atom-list-row-details" */).then(c => wrapFunctional(c.default || c))
export const AtomStreamBarcodeReader = () => import('../../src/components/AtomStreamBarcodeReader.vue' /* webpackChunkName: "components/atom-stream-barcode-reader" */).then(c => wrapFunctional(c.default || c))
export const AtomTitlePageBorder = () => import('../../src/components/AtomTitlePageBorder.vue' /* webpackChunkName: "components/atom-title-page-border" */).then(c => wrapFunctional(c.default || c))
export const AtomTitleSectionIcon = () => import('../../src/components/AtomTitleSectionIcon.vue' /* webpackChunkName: "components/atom-title-section-icon" */).then(c => wrapFunctional(c.default || c))
export const ElmBtnSelectS = () => import('../../src/components/ElmBtnSelectS.vue' /* webpackChunkName: "components/elm-btn-select-s" */).then(c => wrapFunctional(c.default || c))
export const ElmBtnSelectTireNumber = () => import('../../src/components/ElmBtnSelectTireNumber.vue' /* webpackChunkName: "components/elm-btn-select-tire-number" */).then(c => wrapFunctional(c.default || c))
export const ElmCardAlertHint = () => import('../../src/components/ElmCardAlertHint.vue' /* webpackChunkName: "components/elm-card-alert-hint" */).then(c => wrapFunctional(c.default || c))
export const ElmCardCaution = () => import('../../src/components/ElmCardCaution.vue' /* webpackChunkName: "components/elm-card-caution" */).then(c => wrapFunctional(c.default || c))
export const ElmCardDetails = () => import('../../src/components/ElmCardDetails.vue' /* webpackChunkName: "components/elm-card-details" */).then(c => wrapFunctional(c.default || c))
export const ElmCardHistory = () => import('../../src/components/ElmCardHistory.vue' /* webpackChunkName: "components/elm-card-history" */).then(c => wrapFunctional(c.default || c))
export const ElmCardInfoCar = () => import('../../src/components/ElmCardInfoCar.vue' /* webpackChunkName: "components/elm-card-info-car" */).then(c => wrapFunctional(c.default || c))
export const ElmCardListIndex = () => import('../../src/components/ElmCardListIndex.vue' /* webpackChunkName: "components/elm-card-list-index" */).then(c => wrapFunctional(c.default || c))
export const ElmCardListIndexInner = () => import('../../src/components/ElmCardListIndexInner.vue' /* webpackChunkName: "components/elm-card-list-index-inner" */).then(c => wrapFunctional(c.default || c))
export const ElmCardMypage = () => import('../../src/components/ElmCardMypage.vue' /* webpackChunkName: "components/elm-card-mypage" */).then(c => wrapFunctional(c.default || c))
export const ElmCardMypageCustomer = () => import('../../src/components/ElmCardMypageCustomer.vue' /* webpackChunkName: "components/elm-card-mypage-customer" */).then(c => wrapFunctional(c.default || c))
export const ElmCardMypageVehicle = () => import('../../src/components/ElmCardMypageVehicle.vue' /* webpackChunkName: "components/elm-card-mypage-vehicle" */).then(c => wrapFunctional(c.default || c))
export const ElmCardNotExist = () => import('../../src/components/ElmCardNotExist.vue' /* webpackChunkName: "components/elm-card-not-exist" */).then(c => wrapFunctional(c.default || c))
export const ElmCardPrice = () => import('../../src/components/ElmCardPrice.vue' /* webpackChunkName: "components/elm-card-price" */).then(c => wrapFunctional(c.default || c))
export const ElmCardPrivacyPolicy = () => import('../../src/components/ElmCardPrivacyPolicy.vue' /* webpackChunkName: "components/elm-card-privacy-policy" */).then(c => wrapFunctional(c.default || c))
export const ElmCardReservation = () => import('../../src/components/ElmCardReservation.vue' /* webpackChunkName: "components/elm-card-reservation" */).then(c => wrapFunctional(c.default || c))
export const ElmCardReservationS = () => import('../../src/components/ElmCardReservationS.vue' /* webpackChunkName: "components/elm-card-reservation-s" */).then(c => wrapFunctional(c.default || c))
export const ElmCardTire = () => import('../../src/components/ElmCardTire.vue' /* webpackChunkName: "components/elm-card-tire" */).then(c => wrapFunctional(c.default || c))
export const ElmCardWarranty = () => import('../../src/components/ElmCardWarranty.vue' /* webpackChunkName: "components/elm-card-warranty" */).then(c => wrapFunctional(c.default || c))
export const ElmErrorView = () => import('../../src/components/ElmErrorView.vue' /* webpackChunkName: "components/elm-error-view" */).then(c => wrapFunctional(c.default || c))
export const ElmFormCheckBoxMulti = () => import('../../src/components/ElmFormCheckBoxMulti.vue' /* webpackChunkName: "components/elm-form-check-box-multi" */).then(c => wrapFunctional(c.default || c))
export const ElmFormCombobox = () => import('../../src/components/ElmFormCombobox.vue' /* webpackChunkName: "components/elm-form-combobox" */).then(c => wrapFunctional(c.default || c))
export const ElmFormInputBirthday = () => import('../../src/components/ElmFormInputBirthday.vue' /* webpackChunkName: "components/elm-form-input-birthday" */).then(c => wrapFunctional(c.default || c))
export const ElmFormInputPostCode = () => import('../../src/components/ElmFormInputPostCode.vue' /* webpackChunkName: "components/elm-form-input-post-code" */).then(c => wrapFunctional(c.default || c))
export const ElmFormInputTel = () => import('../../src/components/ElmFormInputTel.vue' /* webpackChunkName: "components/elm-form-input-tel" */).then(c => wrapFunctional(c.default || c))
export const ElmFormInputText = () => import('../../src/components/ElmFormInputText.vue' /* webpackChunkName: "components/elm-form-input-text" */).then(c => wrapFunctional(c.default || c))
export const ElmFormItemGroup = () => import('../../src/components/ElmFormItemGroup.vue' /* webpackChunkName: "components/elm-form-item-group" */).then(c => wrapFunctional(c.default || c))
export const ElmFormLabel = () => import('../../src/components/ElmFormLabel.vue' /* webpackChunkName: "components/elm-form-label" */).then(c => wrapFunctional(c.default || c))
export const ElmFormLayoutVer = () => import('../../src/components/ElmFormLayoutVer.vue' /* webpackChunkName: "components/elm-form-layout-ver" */).then(c => wrapFunctional(c.default || c))
export const ElmFormSelect = () => import('../../src/components/ElmFormSelect.vue' /* webpackChunkName: "components/elm-form-select" */).then(c => wrapFunctional(c.default || c))
export const ElmFormSelectGray = () => import('../../src/components/ElmFormSelectGray.vue' /* webpackChunkName: "components/elm-form-select-gray" */).then(c => wrapFunctional(c.default || c))
export const ElmFormSelectMulti = () => import('../../src/components/ElmFormSelectMulti.vue' /* webpackChunkName: "components/elm-form-select-multi" */).then(c => wrapFunctional(c.default || c))
export const ElmFormSelectMultiOutlined = () => import('../../src/components/ElmFormSelectMultiOutlined.vue' /* webpackChunkName: "components/elm-form-select-multi-outlined" */).then(c => wrapFunctional(c.default || c))
export const ElmFormSelectObject = () => import('../../src/components/ElmFormSelectObject.vue' /* webpackChunkName: "components/elm-form-select-object" */).then(c => wrapFunctional(c.default || c))
export const ElmInfoCar  = () => import('../../src/components/ElmInfoCar .vue' /* webpackChunkName: "components/elm-info-car " */).then(c => wrapFunctional(c.default || c))
export const ElmInfoDetails = () => import('../../src/components/ElmInfoDetails.vue' /* webpackChunkName: "components/elm-info-details" */).then(c => wrapFunctional(c.default || c))
export const ElmListRow = () => import('../../src/components/ElmListRow.vue' /* webpackChunkName: "components/elm-list-row" */).then(c => wrapFunctional(c.default || c))
export const ElmListRowIcon = () => import('../../src/components/ElmListRowIcon.vue' /* webpackChunkName: "components/elm-list-row-icon" */).then(c => wrapFunctional(c.default || c))
export const ElmTitleMypage = () => import('../../src/components/ElmTitleMypage.vue' /* webpackChunkName: "components/elm-title-mypage" */).then(c => wrapFunctional(c.default || c))
export const ElmToolbarCardMypage = () => import('../../src/components/ElmToolbarCardMypage.vue' /* webpackChunkName: "components/elm-toolbar-card-mypage" */).then(c => wrapFunctional(c.default || c))
export const OrgComplete = () => import('../../src/components/OrgComplete.vue' /* webpackChunkName: "components/org-complete" */).then(c => wrapFunctional(c.default || c))
export const OrgDialog = () => import('../../src/components/OrgDialog.vue' /* webpackChunkName: "components/org-dialog" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogCaution = () => import('../../src/components/OrgDialogCaution.vue' /* webpackChunkName: "components/org-dialog-caution" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogCautionReservation = () => import('../../src/components/OrgDialogCautionReservation.vue' /* webpackChunkName: "components/org-dialog-caution-reservation" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogDescription = () => import('../../src/components/OrgDialogDescription.vue' /* webpackChunkName: "components/org-dialog-description" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogOneBtn = () => import('../../src/components/OrgDialogOneBtn.vue' /* webpackChunkName: "components/org-dialog-one-btn" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogSearchTire = () => import('../../src/components/OrgDialogSearchTire.vue' /* webpackChunkName: "components/org-dialog-search-tire" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogSelectPrefecture = () => import('../../src/components/OrgDialogSelectPrefecture.vue' /* webpackChunkName: "components/org-dialog-select-prefecture" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogTwoBtns = () => import('../../src/components/OrgDialogTwoBtns.vue' /* webpackChunkName: "components/org-dialog-two-btns" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogWarranty = () => import('../../src/components/OrgDialogWarranty.vue' /* webpackChunkName: "components/org-dialog-warranty" */).then(c => wrapFunctional(c.default || c))
export const OrgDialogZaiko = () => import('../../src/components/OrgDialogZaiko.vue' /* webpackChunkName: "components/org-dialog-zaiko" */).then(c => wrapFunctional(c.default || c))
export const OrgError = () => import('../../src/components/OrgError.vue' /* webpackChunkName: "components/org-error" */).then(c => wrapFunctional(c.default || c))
export const OrgErrorDialog = () => import('../../src/components/OrgErrorDialog.vue' /* webpackChunkName: "components/org-error-dialog" */).then(c => wrapFunctional(c.default || c))
export const OrgFooterNav = () => import('../../src/components/OrgFooterNav.vue' /* webpackChunkName: "components/org-footer-nav" */).then(c => wrapFunctional(c.default || c))
export const OrgFormCustomerInfo = () => import('../../src/components/OrgFormCustomerInfo.vue' /* webpackChunkName: "components/org-form-customer-info" */).then(c => wrapFunctional(c.default || c))
export const OrgFormVehicles = () => import('../../src/components/OrgFormVehicles.vue' /* webpackChunkName: "components/org-form-vehicles" */).then(c => wrapFunctional(c.default || c))
export const OrgHeader = () => import('../../src/components/OrgHeader.vue' /* webpackChunkName: "components/org-header" */).then(c => wrapFunctional(c.default || c))
export const OrgLoading = () => import('../../src/components/OrgLoading.vue' /* webpackChunkName: "components/org-loading" */).then(c => wrapFunctional(c.default || c))
export const OrgMypageTabs = () => import('../../src/components/OrgMypageTabs.vue' /* webpackChunkName: "components/org-mypage-tabs" */).then(c => wrapFunctional(c.default || c))
export const OrgRegistrationFormCustomerInfo = () => import('../../src/components/OrgRegistrationFormCustomerInfo.vue' /* webpackChunkName: "components/org-registration-form-customer-info" */).then(c => wrapFunctional(c.default || c))
export const OrgSearchTireFromCarModel = () => import('../../src/components/OrgSearchTireFromCarModel.vue' /* webpackChunkName: "components/org-search-tire-from-car-model" */).then(c => wrapFunctional(c.default || c))
export const OrgSelectSchedule = () => import('../../src/components/OrgSelectSchedule.vue' /* webpackChunkName: "components/org-select-schedule" */).then(c => wrapFunctional(c.default || c))
export const OrgSnackbar = () => import('../../src/components/OrgSnackbar.vue' /* webpackChunkName: "components/org-snackbar" */).then(c => wrapFunctional(c.default || c))
export const StreamBarcodeReader = () => import('../../src/components/StreamBarcodeReader.vue' /* webpackChunkName: "components/stream-barcode-reader" */).then(c => wrapFunctional(c.default || c))
export const TmpError = () => import('../../src/components/TmpError.vue' /* webpackChunkName: "components/tmp-error" */).then(c => wrapFunctional(c.default || c))
export const TmpGuest = () => import('../../src/components/TmpGuest.vue' /* webpackChunkName: "components/tmp-guest" */).then(c => wrapFunctional(c.default || c))
export const TmpLineRenewal = () => import('../../src/components/TmpLineRenewal.vue' /* webpackChunkName: "components/tmp-line-renewal" */).then(c => wrapFunctional(c.default || c))
export const TmpLocation = () => import('../../src/components/TmpLocation.vue' /* webpackChunkName: "components/tmp-location" */).then(c => wrapFunctional(c.default || c))
export const TmpMypage = () => import('../../src/components/TmpMypage.vue' /* webpackChunkName: "components/tmp-mypage" */).then(c => wrapFunctional(c.default || c))
export const TmpOutOfArea = () => import('../../src/components/TmpOutOfArea.vue' /* webpackChunkName: "components/tmp-out-of-area" */).then(c => wrapFunctional(c.default || c))
export const TmpRegiter = () => import('../../src/components/TmpRegiter.vue' /* webpackChunkName: "components/tmp-regiter" */).then(c => wrapFunctional(c.default || c))
export const TmpUnderConstruction = () => import('../../src/components/TmpUnderConstruction.vue' /* webpackChunkName: "components/tmp-under-construction" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../src/components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../src/components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
