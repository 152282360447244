// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".text-black_CLkv3{color:rgba(0,0,0,.871)}.text-white_RmqUH{color:#fff}.text-red_Wi\\+hE{color:#c43}.text-blue_XAhWL{color:#4587c1}.color-red_NEI7h{background:#c43}.color-blue-grey_Ma4p1{background:#54617a}.color-white_ahPIf{background:#fff}.pa-1-5_fyaPB{padding:6px}.mt-20_cW7bz{margin-top:80px}.mt-24_mZ3UT{margin-top:96px}p{margin:0;padding:0}a{-webkit-text-decoration:none;text-decoration:none}.v-text-field--outlined_bFSGS fieldset,.v-text-field--outlined_bFSGS.v-input--is-focused_PUOU3 fieldset{border-width:0}.v-text-field--outlined_bFSGS.v-input--has-state_LpXqV fieldset{border:2px solid red!important}.v-input__slot_ypfvD{min-height:auto!important;padding:0 12px!important}.v-input_ytWmC{caret-color:#1867c0!important;font-size:14px!important}.v-input__append-inner_N\\+qdR{margin-top:4px!important}.v-slide-group__next_tLcqA,.v-slide-group__prev_sewPx{flex:0 1!important;min-width:0!important}.v-expansion-panel-header_Zja1O{min-height:40px!important}.v-btn_CMtgE{min-width:auto!important}.v-tab_-cFEo{padding:0 12px!important}.v-item-group_saszX.v-bottom-navigation_u7yq2 .v-btn_CMtgE.v-size--default_NEizh{height:inherit}.theme--light_1u29-.v-bottom-navigation_u7yq2{background-color:#eee!important}.v-bottom-navigation_u7yq2{height:auto!important}.v-bottom-navigation_u7yq2 .v-btn_CMtgE{margin-bottom:env(safe-area-inset-bottom)}.bl_error_title_blnTp{font-size:26px;font-weight:700;line-height:1.3;margin-top:24px;text-align:center;white-space:pre-wrap}.bl_error_border_9MVkJ{border-bottom:2px solid #c43;margin:10px auto 0;width:40px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text-black": "text-black_CLkv3",
	"text-white": "text-white_RmqUH",
	"text-red": "text-red_Wi+hE",
	"text-blue": "text-blue_XAhWL",
	"color-red": "color-red_NEI7h",
	"color-blue-grey": "color-blue-grey_Ma4p1",
	"color-white": "color-white_ahPIf",
	"pa-1-5": "pa-1-5_fyaPB",
	"mt-20": "mt-20_cW7bz",
	"mt-24": "mt-24_mZ3UT",
	"v-text-field--outlined": "v-text-field--outlined_bFSGS",
	"v-input--is-focused": "v-input--is-focused_PUOU3",
	"v-input--has-state": "v-input--has-state_LpXqV",
	"v-input__slot": "v-input__slot_ypfvD",
	"v-input": "v-input_ytWmC",
	"v-input__append-inner": "v-input__append-inner_N+qdR",
	"v-slide-group__next": "v-slide-group__next_tLcqA",
	"v-slide-group__prev": "v-slide-group__prev_sewPx",
	"v-expansion-panel-header": "v-expansion-panel-header_Zja1O",
	"v-btn": "v-btn_CMtgE",
	"v-tab": "v-tab_-cFEo",
	"v-item-group": "v-item-group_saszX",
	"v-bottom-navigation": "v-bottom-navigation_u7yq2",
	"v-size--default": "v-size--default_NEizh",
	"theme--light": "theme--light_1u29-",
	"bl_error_title": "bl_error_title_blnTp",
	"bl_error_border": "bl_error_border_9MVkJ"
};
module.exports = ___CSS_LOADER_EXPORT___;
