import { GraphQLClient } from 'graphql-request'
import * as Dom from 'graphql-request/dist/types.dom'
import gql from 'graphql-tag'
export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K]
}
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>
}
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

export type AnswerInput = {
  questionCode: Scalars['String']
  answerValues: Array<AnswerValueInput>
}

export type AnswerValueInput = {
  no?: Maybe<Scalars['String']>
  text: Scalars['String']
}

export type AuthResult = {
  accessToken: Scalars['String']
  user: User
}

export type CarInfo = {
  carId?: Maybe<Scalars['Int']>
  carName?: Maybe<Scalars['String']>
  vehicleMakerName?: Maybe<Scalars['String']>
  vehicleTypeName?: Maybe<Scalars['String']>
  vehicleModel?: Maybe<Scalars['String']>
  periodFrom?: Maybe<Scalars['String']>
  periodTo?: Maybe<Scalars['String']>
  vehicleGrade?: Maybe<Scalars['String']>
  numberPlateArea?: Maybe<Scalars['String']>
  numberPlateClass?: Maybe<Scalars['String']>
  numberPlateHira?: Maybe<Scalars['String']>
  numberPlateSeries?: Maybe<Scalars['String']>
  firstYearRegistration?: Maybe<Scalars['String']>
  mileage?: Maybe<Scalars['Int']>
  tireUpdateDate?: Maybe<Scalars['String']>
  tireRotationDate?: Maybe<Scalars['String']>
  inspection?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type CarList = {
  carInfo: CarInfo
  purchase?: Maybe<Purchase>
}

export type CreateEnqueteAnswerInput = {
  enqueteType: Scalars['String']
  answers: Array<AnswerInput>
  adCode?: Maybe<Scalars['String']>
  metaData?: Maybe<EnqueteAnswerMetaDataInput>
}

export type CreateEnqueteAnswerResult = {
  answerId: Scalars['String']
}

export type CreateQuotationInput = {
  carId?: Maybe<Scalars['Int']>
  shopId?: Maybe<Scalars['Int']>
  prefectures?: Maybe<Scalars['String']>
  adCode?: Maybe<Scalars['String']>
  quotationInfoList: Array<QuotationInfoInput>
}

export type CreateQuotationResult = {
  quatationId?: Maybe<Scalars['Int']>
}

export type CreateVehicleInput = {
  carId?: Maybe<Scalars['Int']>
  vehicleMakerName?: Maybe<Scalars['String']>
  vehicleTypeName?: Maybe<Scalars['String']>
  vehicleModel?: Maybe<Scalars['String']>
  firstYearRegistration?: Maybe<Scalars['String']>
  numberPlateArea: Scalars['String']
  numberPlateClass: Scalars['String']
  numberPlateHira: Scalars['String']
  numberPlateSeries: Scalars['String']
  mileage?: Maybe<Scalars['String']>
  tireUpdateDate?: Maybe<Scalars['String']>
  tireRotationDate?: Maybe<Scalars['String']>
  inspection?: Maybe<Scalars['String']>
  note?: Maybe<Scalars['String']>
}

export type CreateVehicleResult = {
  carId?: Maybe<Scalars['Int']>
}

export type CustomerInfo = {
  customerId: Scalars['Int']
  customerKind: Scalars['Int']
  corpName?: Maybe<Scalars['String']>
  corpNameKana?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  firstName?: Maybe<Scalars['String']>
  lastNameKana?: Maybe<Scalars['String']>
  firstNameKana?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  prefectureName?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  buildingName?: Maybe<Scalars['String']>
  tel?: Maybe<Scalars['String']>
  mailAddress?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  sex?: Maybe<Scalars['Int']>
  shopId: Scalars['Int']
  shopName: Scalars['String']
  lineId?: Maybe<Scalars['String']>
  entryDate?: Maybe<Scalars['String']>
}

export type CustomerScheduleInfo = {
  scheduleId: Scalars['Int']
  shopId: Scalars['Int']
  date: Scalars['String']
  startTime: Scalars['String']
  itemName?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  carName?: Maybe<Scalars['String']>
  lineId: Scalars['String']
  jan?: Maybe<Scalars['String']>
  workingTime?: Maybe<Scalars['Int']>
  createDate?: Maybe<Scalars['String']>
  workingId?: Maybe<Scalars['Int']>
}

export type DeleteQuotationInput = {
  quatationId: Scalars['Int']
}

export type DeleteQuotationResult = {
  result: Scalars['Boolean']
}

export type DeleteScheduleInput = {
  scheduleId: Scalars['Int']
}

export type DeleteVehicleInput = {
  carId: Scalars['Int']
}

export type DeleteVehicleResult = {
  carId?: Maybe<Scalars['Int']>
}

export type EnqueteAnswerMetaDataInput = {
  shopId?: Maybe<Scalars['Int']>
  shopName?: Maybe<Scalars['String']>
}

export type IncidentalExpenses = {
  jan?: Maybe<Scalars['String']>
  beelineCode?: Maybe<Scalars['String']>
  itemName?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Int']>
}

export type IncidentalExpensesOfQuotation = {
  itemCode?: Maybe<Scalars['String']>
  itemName?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
}

export type InsuranceInfo = {
  punctureRepair?: Maybe<Scalars['Int']>
  insurance?: Maybe<Scalars['Int']>
  paidInsurance?: Maybe<Scalars['Int']>
  paidInsuranceIncidentalExpenses?: Maybe<Scalars['Int']>
  cid?: Maybe<Scalars['String']>
  insuranceJoinDate?: Maybe<Scalars['String']>
  insurancePeriod?: Maybe<Scalars['String']>
  insuranceShopName?: Maybe<Scalars['String']>
  insuranceItemName?: Maybe<Scalars['String']>
  insuranceTireSize?: Maybe<Scalars['String']>
}

export type IntegrateUserInput = {
  lineToken: Scalars['String']
  bpsToken: Scalars['String']
}

export type IntegrateUserResult = {
  result: Scalars['Boolean']
}

export type IsEstimateInput = {
  shopId?: Maybe<Scalars['Int']>
  prefectures?: Maybe<Scalars['String']>
  adCode?: Maybe<Scalars['String']>
}

export type IsEstimateResult = {
  isEstimate: Scalars['Int']
}

export type LoginInput = {
  lineIdToken: Scalars['String']
}

export type MakerInput = {
  makerGroup?: Maybe<Scalars['Int']>
  makerId?: Maybe<Scalars['String']>
}

export type MarkList = {
  groupName?: Maybe<Scalars['String']>
  groupId?: Maybe<Scalars['Int']>
  markName?: Maybe<Scalars['String']>
  markId?: Maybe<Scalars['Int']>
  check?: Maybe<Scalars['Int']>
}

export type MarkListsInput = {
  shopId?: Maybe<Scalars['Int']>
  prefectures?: Maybe<Scalars['String']>
  adCode?: Maybe<Scalars['String']>
}

export type Mutation = {
  authenticate: AuthResult
  registerUser: RegisterUserResult
  verifyUserQRToken: CustomerInfo
  integrateUser: IntegrateUserResult
  updateUser: RegisterUserResult
  unsubscribe: UnsubscribeResult
  createVehicle: CreateVehicleResult
  deleteVehicle: DeleteVehicleResult
  createQuotation: CreateQuotationResult
  deleteQuotation: DeleteQuotationResult
  createEnqueteAnswer: CreateEnqueteAnswerResult
  putLog: PutLogResult
  registSchedule: ScheduleResult
  updateSchedule: ScheduleResult
  deleteSchedule: ScheduleResult
}

export type MutationAuthenticateArgs = {
  input: LoginInput
}

export type MutationRegisterUserArgs = {
  input: RegisterUserInput
}

export type MutationVerifyUserQrTokenArgs = {
  input: VerifyUserQrTokenInput
}

export type MutationIntegrateUserArgs = {
  input: IntegrateUserInput
}

export type MutationUpdateUserArgs = {
  input: UpdateUserInput
}

export type MutationCreateVehicleArgs = {
  input: CreateVehicleInput
}

export type MutationDeleteVehicleArgs = {
  input: DeleteVehicleInput
}

export type MutationCreateQuotationArgs = {
  input: CreateQuotationInput
}

export type MutationDeleteQuotationArgs = {
  input: DeleteQuotationInput
}

export type MutationCreateEnqueteAnswerArgs = {
  input: CreateEnqueteAnswerInput
}

export type MutationPutLogArgs = {
  input: PutLogInput
}

export type MutationRegistScheduleArgs = {
  input: RegistScheduleInput
}

export type MutationUpdateScheduleArgs = {
  input: UpdateScheduleInput
}

export type MutationDeleteScheduleArgs = {
  input: DeleteScheduleInput
}

export type PostCodeInput = {
  postcode: Scalars['String']
}

export type PostCodeResult = {
  pref: Scalars['String']
  city: Scalars['String']
  town: Scalars['String']
}

export type PrefectureInput = {
  latitude: Scalars['Float']
  longitude: Scalars['Float']
}

export type PrefectureResult = {
  prefecture?: Maybe<Scalars['String']>
}

export type Purchase = {
  slipId?: Maybe<Scalars['String']>
  purchaseDate?: Maybe<Scalars['String']>
  shopName?: Maybe<Scalars['String']>
  purchaseUserName?: Maybe<Scalars['String']>
  regiUserName?: Maybe<Scalars['String']>
  amount?: Maybe<Scalars['Float']>
  customerId?: Maybe<Scalars['Int']>
  lineId?: Maybe<Scalars['String']>
  carId?: Maybe<Scalars['String']>
  purchaseItemList?: Maybe<Array<PurchaseItem>>
  insuranceInfo?: Maybe<InsuranceInfo>
}

export type PurchaseDetailsInput = {
  slipId: Scalars['String']
}

export type PurchaseDetailsResult = {
  purchaseInfo?: Maybe<Purchase>
}

export type PurchaseItem = {
  productName?: Maybe<Scalars['String']>
  isOld?: Maybe<Scalars['Int']>
  makerName?: Maybe<Scalars['String']>
  size?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  amount?: Maybe<Scalars['Int']>
  cost?: Maybe<Scalars['Int']>
  jan?: Maybe<Scalars['String']>
  beelineCode?: Maybe<Scalars['String']>
  tireSize?: Maybe<Scalars['Int']>
  flatRate?: Maybe<Scalars['Int']>
  tireInch?: Maybe<Scalars['Int']>
}

export type PurchasesInput = {
  purchaseDate?: Maybe<Scalars['String']>
}

export type PutLogInput = {
  msg: Scalars['String']
  meta?: Maybe<Scalars['String']>
  isErrorLog?: Maybe<Scalars['Boolean']>
}

export type PutLogResult = {
  result: Scalars['Boolean']
}

export type Query = {
  user: CustomerInfo
  vehicles: Array<CarList>
  vehicleDetails: CarList
  vehicleTypes: Array<VehicleType>
  vehicleMakers: Array<VehicleMaker>
  vehicleTypesByMaker: Array<VehicleType>
  vehiclePeriods: Array<VehicleInfo>
  tires: Array<TireInfo>
  tireSizes: TireSizeInfo
  tireMakers: Array<TireMaker>
  isEstimate: IsEstimateResult
  quotations: Array<QuotationInfo>
  quotationDetails: QuotationInfo
  purchases: Array<Purchase>
  purchaseDetails: PurchaseDetailsResult
  stores: Array<StoreInfo>
  postCode: PostCodeResult
  prefecture: PrefectureResult
  markLists: Array<MarkList>
  workingNames: Array<WorkingNameInfo>
  schedules: Array<ScheduleInfo>
  searchCustomerSchedules: Array<CustomerScheduleInfo>
}

export type QueryVehicleDetailsArgs = {
  input: VehicleDetailsInput
}

export type QueryVehicleTypesArgs = {
  input: VehicleTypesInput
}

export type QueryVehicleTypesByMakerArgs = {
  input: VehicleTypesByMakerInput
}

export type QueryVehiclePeriodsArgs = {
  input: VehiclePeriodsInput
}

export type QueryTiresArgs = {
  input: TiresInput
}

export type QueryTireSizesArgs = {
  input: TireSizesInput
}

export type QueryIsEstimateArgs = {
  input: IsEstimateInput
}

export type QueryQuotationDetailsArgs = {
  input: QuotationDetailsInput
}

export type QueryPurchasesArgs = {
  input: PurchasesInput
}

export type QueryPurchaseDetailsArgs = {
  input: PurchaseDetailsInput
}

export type QueryStoresArgs = {
  input?: Maybe<StoresInput>
}

export type QueryPostCodeArgs = {
  input: PostCodeInput
}

export type QueryPrefectureArgs = {
  input: PrefectureInput
}

export type QueryMarkListsArgs = {
  input: MarkListsInput
}

export type QueryWorkingNamesArgs = {
  input: WorkingNamesInput
}

export type QuerySchedulesArgs = {
  input: SchedulesInput
}

export type QuerySearchCustomerSchedulesArgs = {
  input: SearchCustomerScheduleInput
}

export type QuotationDetailsInput = {
  quatationId: Scalars['Int']
  shopId?: Maybe<Scalars['Int']>
}

export type QuotationInfo = {
  quatationId?: Maybe<Scalars['Int']>
  jan?: Maybe<Scalars['String']>
  quatationMakeDate: Scalars['String']
  amount: Scalars['Int']
  quantity: Scalars['Int']
  makerItemCode?: Maybe<Scalars['String']>
  beelineCode?: Maybe<Scalars['String']>
  comCode?: Maybe<Scalars['String']>
  makerId?: Maybe<Scalars['String']>
  makerName?: Maybe<Scalars['String']>
  itemName: Scalars['String']
  size?: Maybe<Scalars['String']>
  tireLi?: Maybe<Scalars['String']>
  tireSr?: Maybe<Scalars['String']>
  tireXl?: Maybe<Scalars['Int']>
  tireRunFlat?: Maybe<Scalars['Int']>
  allSeasonsFlg?: Maybe<Scalars['Int']>
  wtrFlg?: Maybe<Scalars['Int']>
  efficientSpec?: Maybe<Scalars['String']>
  wetSpec?: Maybe<Scalars['String']>
  miniCarFlg?: Maybe<Scalars['Int']>
  minivanFlg?: Maybe<Scalars['Int']>
  suvFlg?: Maybe<Scalars['Int']>
  truckFlg?: Maybe<Scalars['Int']>
  importItem?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  brandAlphaName?: Maybe<Scalars['String']>
  brandKanaName?: Maybe<Scalars['String']>
  patternAlphaName?: Maybe<Scalars['String']>
  patternKanaName?: Maybe<Scalars['String']>
  sale1?: Maybe<Scalars['Int']>
  sale2?: Maybe<Scalars['Int']>
  sale3?: Maybe<Scalars['Int']>
  sale4?: Maybe<Scalars['Int']>
  sale5?: Maybe<Scalars['Int']>
  sale6?: Maybe<Scalars['Int']>
  sale7?: Maybe<Scalars['Int']>
  sale8?: Maybe<Scalars['Int']>
  tireImage?: Maybe<Scalars['String']>
  incidentalExpenses?: Maybe<Array<IncidentalExpensesOfQuotation>>
}

export type QuotationInfoInput = {
  quantity: Scalars['Int']
  amount: Scalars['Int']
  jan?: Maybe<Scalars['String']>
  makerItemCode?: Maybe<Scalars['String']>
  beelineCode?: Maybe<Scalars['String']>
  comCode?: Maybe<Scalars['String']>
}

export type RegistScheduleInput = {
  shopId: Scalars['Int']
  baseDate: Scalars['String']
  startTime: Scalars['String']
  jan?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  carId?: Maybe<Scalars['Int']>
  carFreeWord?: Maybe<Scalars['String']>
  workingId?: Maybe<Scalars['Int']>
  bringingInTireSize?: Maybe<Scalars['String']>
  bringingInQuantity?: Maybe<Scalars['Int']>
}

export type RegisterUserInput = {
  customerKind: Scalars['Int']
  corpName?: Maybe<Scalars['String']>
  corpNameKana?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  firstName: Scalars['String']
  lastNameKana?: Maybe<Scalars['String']>
  firstNameKana?: Maybe<Scalars['String']>
  postCode?: Maybe<Scalars['String']>
  prefectureName?: Maybe<Scalars['String']>
  cityName?: Maybe<Scalars['String']>
  areaName?: Maybe<Scalars['String']>
  buildingName?: Maybe<Scalars['String']>
  tel?: Maybe<Scalars['String']>
  mailAddress?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  sex?: Maybe<Scalars['Int']>
  shopId: Scalars['Int']
}

export type RegisterUserResult = {
  customerId: Scalars['Int']
  lineId: Scalars['String']
}

export type ScheduleInfo = {
  date: Scalars['String']
  timeList?: Maybe<Array<TimeList>>
}

export type ScheduleResult = {
  resultKind: Scalars['Int']
}

export type SchedulesInput = {
  shopId: Scalars['Int']
  baseDate: Scalars['String']
  jan?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  ignoreScheduleId?: Maybe<Scalars['Int']>
  workingTime?: Maybe<Scalars['Int']>
  workingId?: Maybe<Scalars['Int']>
}

export type SearchCustomerScheduleInput = {
  carId?: Maybe<Scalars['Int']>
}

export type StoreInfo = {
  shopId?: Maybe<Scalars['Int']>
  shopName?: Maybe<Scalars['String']>
  shopNameAbb?: Maybe<Scalars['String']>
  prefecture?: Maybe<Scalars['String']>
  prefectureId?: Maybe<Scalars['Int']>
  tel?: Maybe<Scalars['String']>
  fax?: Maybe<Scalars['String']>
  post?: Maybe<Scalars['String']>
  address?: Maybe<Scalars['String']>
  openingHours?: Maybe<Scalars['String']>
  receiptComment?: Maybe<Scalars['String']>
  url?: Maybe<Scalars['String']>
  linePitFlg: Scalars['Int']
}

export type StoresInput = {
  beelineFlg?: Maybe<Scalars['Int']>
  baseShopId?: Maybe<Scalars['Int']>
  baseLatitude?: Maybe<Scalars['String']>
  baseLongitude?: Maybe<Scalars['String']>
  itemCode?: Maybe<Scalars['String']>
  quantity?: Maybe<Scalars['Int']>
  ignoreEstimateFlg?: Maybe<Scalars['Int']>
  linePitFlg?: Maybe<Scalars['Int']>
}

export type TimeList = {
  startTime: Scalars['String']
  scheduleKind: Scalars['Int']
}

export type TireInfo = {
  jan?: Maybe<Scalars['String']>
  makerItemCode?: Maybe<Scalars['String']>
  beelineCode?: Maybe<Scalars['String']>
  comCode?: Maybe<Scalars['String']>
  makerId: Scalars['Float']
  makerName: Scalars['String']
  itemName: Scalars['String']
  size: Scalars['String']
  tireLi?: Maybe<Scalars['String']>
  tireSr?: Maybe<Scalars['String']>
  tireXl?: Maybe<Scalars['Int']>
  tireRunFlat?: Maybe<Scalars['Int']>
  allSeasonsFlg?: Maybe<Scalars['Int']>
  wtrFlg?: Maybe<Scalars['Int']>
  efficientSpec?: Maybe<Scalars['String']>
  wetSpec?: Maybe<Scalars['String']>
  miniCarFlg?: Maybe<Scalars['Int']>
  minivanFlg?: Maybe<Scalars['Int']>
  suvFlg?: Maybe<Scalars['Int']>
  truckFlg?: Maybe<Scalars['Int']>
  importItem?: Maybe<Scalars['Int']>
  description?: Maybe<Scalars['String']>
  brandAlphaName?: Maybe<Scalars['String']>
  brandKanaName?: Maybe<Scalars['String']>
  patternAlphaName?: Maybe<Scalars['String']>
  patternKanaName?: Maybe<Scalars['String']>
  sale1: Scalars['Float']
  sale2: Scalars['Float']
  sale3?: Maybe<Scalars['Int']>
  sale4?: Maybe<Scalars['Int']>
  sale5?: Maybe<Scalars['Int']>
  sale6?: Maybe<Scalars['Int']>
  sale7?: Maybe<Scalars['Int']>
  sale8?: Maybe<Scalars['Int']>
  tireImage?: Maybe<Scalars['String']>
  shopStock: Scalars['Int']
  warehouseStock: Scalars['Int']
  incidentalExpensesList: Array<IncidentalExpenses>
  insuranceInfo?: Maybe<TireInsuranceInfo>
}

export type TireInfoInput = {
  tireKind?: Maybe<Scalars['Int']>
  tireSize?: Maybe<Scalars['Int']>
  flatRate?: Maybe<Scalars['Int']>
  tireInch?: Maybe<Scalars['Int']>
  isTruck?: Maybe<Scalars['Int']>
  itemCode?: Maybe<Scalars['String']>
}

export type TireInsuranceInfo = {
  beelineCode: Scalars['String']
  itemName: Scalars['String']
  amount: Scalars['Int']
}

export type TireMaker = {
  makerId?: Maybe<Scalars['Int']>
  makerName?: Maybe<Scalars['String']>
  makerGroup?: Maybe<Scalars['Int']>
  alphaName?: Maybe<Scalars['String']>
  kanaName?: Maybe<Scalars['String']>
  abbName?: Maybe<Scalars['String']>
}

export type TireSizeInfo = {
  tireWidthList?: Maybe<Array<Scalars['String']>>
  obtList?: Maybe<Array<Scalars['String']>>
  inchList?: Maybe<Array<Scalars['String']>>
}

export type TireSizesInput = {
  baseTireWidth?: Maybe<Scalars['String']>
  baseObt?: Maybe<Scalars['String']>
  baseInch?: Maybe<Scalars['String']>
}

export type TiresInput = {
  offset?: Maybe<Scalars['Int']>
  limit?: Maybe<Scalars['Int']>
  sortMode?: Maybe<Scalars['Int']>
  stock?: Maybe<Scalars['Int']>
  carName?: Maybe<Scalars['String']>
  period?: Maybe<Scalars['Int']>
  shopId?: Maybe<Scalars['Int']>
  prefectures?: Maybe<Scalars['String']>
  adCode?: Maybe<Scalars['String']>
  insurance?: Maybe<Scalars['Int']>
  tireInfo: TireInfoInput
  maker?: Maybe<MakerInput>
}

export type UnsubscribeResult = {
  result: Scalars['Boolean']
}

export type UpdateScheduleInput = {
  scheduleId: Scalars['Int']
  shopId: Scalars['Int']
  baseDate: Scalars['String']
  startTime: Scalars['String']
}

export type UpdateUserInput = {
  customerKind: Scalars['Int']
  corpName?: Maybe<Scalars['String']>
  corpNameKana?: Maybe<Scalars['String']>
  lastName: Scalars['String']
  firstName: Scalars['String']
  lastNameKana?: Maybe<Scalars['String']>
  firstNameKana?: Maybe<Scalars['String']>
  postCode: Scalars['String']
  prefectureName: Scalars['String']
  cityName: Scalars['String']
  areaName: Scalars['String']
  buildingName?: Maybe<Scalars['String']>
  tel: Scalars['String']
  mailAddress?: Maybe<Scalars['String']>
  birthday?: Maybe<Scalars['String']>
  sex?: Maybe<Scalars['Int']>
  shopId: Scalars['Int']
}

export type User = {
  lineId: Scalars['String']
  userNumber?: Maybe<Scalars['Float']>
}

export type VehicleDetailsInput = {
  carId: Scalars['Int']
}

export type VehicleInfo = {
  vehicleMakerName?: Maybe<Scalars['String']>
  vehicleTypeName?: Maybe<Scalars['String']>
  vehiclePeriodFrom?: Maybe<Scalars['String']>
  vehiclePeriodTo?: Maybe<Scalars['String']>
  vehicleModelName?: Maybe<Scalars['String']>
  vehicleTireSizeList: Array<VehicleTireSize>
}

export type VehicleMaker = {
  vehicleMakerId: Scalars['Int']
  vehicleMakerName: Scalars['String']
}

export type VehiclePeriodsInput = {
  vehicleMakerName?: Maybe<Scalars['String']>
  vehicleTypeName?: Maybe<Scalars['String']>
  freeWord?: Maybe<Scalars['String']>
}

export type VehicleTireSize = {
  size?: Maybe<Scalars['String']>
  tireSize?: Maybe<Scalars['Int']>
  flatRate?: Maybe<Scalars['Int']>
  tireInch?: Maybe<Scalars['Int']>
}

export type VehicleType = {
  vehicleMakerName?: Maybe<Scalars['String']>
  vehicleTypeName?: Maybe<Scalars['String']>
}

export type VehicleTypesByMakerInput = {
  vehicleMakerName: Scalars['String']
}

export type VehicleTypesInput = {
  vehicleModel: Scalars['String']
}

export type VerifyUserQrTokenInput = {
  token: Scalars['String']
}

export type WorkingNameInfo = {
  workingId: Scalars['Int']
  workingName: Scalars['String']
  workingTime: Scalars['Int']
  needTireEstimate: Scalars['Int']
  bringingIn: Scalars['Int']
}

export type WorkingNamesInput = {
  shopId?: Maybe<Scalars['Int']>
  tireEstimate?: Maybe<Scalars['Int']>
}

export type UserAttributesFragment = Pick<User, 'lineId' | 'userNumber'>

export type RegisterUserResultAttributesFragment = Pick<
  RegisterUserResult,
  'customerId' | 'lineId'
>

export type CustomerInfoAttributesFragment = Pick<
  CustomerInfo,
  | 'customerId'
  | 'customerKind'
  | 'corpName'
  | 'corpNameKana'
  | 'lastName'
  | 'firstName'
  | 'lastNameKana'
  | 'firstNameKana'
  | 'postCode'
  | 'address'
  | 'prefectureName'
  | 'cityName'
  | 'areaName'
  | 'buildingName'
  | 'tel'
  | 'mailAddress'
  | 'birthday'
  | 'sex'
  | 'shopId'
  | 'shopName'
  | 'lineId'
>

export type IntegrateUserResultAttributesFragment = Pick<
  IntegrateUserResult,
  'result'
>

export type DeleteVehicleResultAttributesFragment = Pick<
  DeleteVehicleResult,
  'carId'
>

export type CreateVehicleResultAttributesFragment = Pick<
  CreateVehicleResult,
  'carId'
>

export type VehicleTypeResultAttributesFragment = Pick<
  VehicleType,
  'vehicleMakerName' | 'vehicleTypeName'
>

export type DeleteQuotationResultAttributesFragment = Pick<
  DeleteQuotationResult,
  'result'
>

export type CreateQuotationResultAttributesFragment = Pick<
  CreateQuotationResult,
  'quatationId'
>

export type UnsubscribeResultAttributesFragment = Pick<
  UnsubscribeResult,
  'result'
>

export type CreateEnqueteAnswerResultAttributesFragment = Pick<
  CreateEnqueteAnswerResult,
  'answerId'
>

export type AuthResultAttributesFragment = Pick<AuthResult, 'accessToken'> & {
  user: UserAttributesFragment
}

export type PurchaseDetailsResultAttributesFragment = {
  purchaseInfo?: Maybe<PurchaseAttributesFragment>
}

export type PostCodeResultAttributesFragment = Pick<
  PostCodeResult,
  'pref' | 'city' | 'town'
>

export type VehicleTireSizeResultAttributesFragment = Pick<
  VehicleTireSize,
  'size' | 'tireSize' | 'flatRate' | 'tireInch'
>

export type VehiclePeriodsResultAttributesFragment = Pick<
  VehicleInfo,
  | 'vehicleMakerName'
  | 'vehicleTypeName'
  | 'vehiclePeriodFrom'
  | 'vehiclePeriodTo'
  | 'vehicleModelName'
> & { vehicleTireSizeList: Array<VehicleTireSizeResultAttributesFragment> }

export type PrefectureResultAttributesFragment = Pick<
  PrefectureResult,
  'prefecture'
>

export type IsEstimateResultAttributesFragment = Pick<
  IsEstimateResult,
  'isEstimate'
>

export type CarInfoAttributesFragment = Pick<
  CarInfo,
  | 'carId'
  | 'carName'
  | 'vehicleMakerName'
  | 'vehicleTypeName'
  | 'vehicleModel'
  | 'periodFrom'
  | 'periodTo'
  | 'vehicleGrade'
  | 'numberPlateArea'
  | 'numberPlateClass'
  | 'numberPlateHira'
  | 'numberPlateSeries'
  | 'firstYearRegistration'
  | 'mileage'
  | 'tireUpdateDate'
  | 'tireRotationDate'
  | 'inspection'
  | 'note'
>

export type PurchaseAttributesFragment = Pick<
  Purchase,
  | 'slipId'
  | 'purchaseDate'
  | 'shopName'
  | 'purchaseUserName'
  | 'regiUserName'
  | 'amount'
> & {
  purchaseItemList?: Maybe<Array<PurchaseItemAttributesFragment>>
  insuranceInfo?: Maybe<InsuranceInfoAttributesFragment>
}

export type PurchaseItemAttributesFragment = Pick<
  PurchaseItem,
  | 'productName'
  | 'isOld'
  | 'makerName'
  | 'size'
  | 'quantity'
  | 'amount'
  | 'cost'
  | 'jan'
  | 'beelineCode'
  | 'tireSize'
  | 'flatRate'
  | 'tireInch'
>

export type TireInsuranceInfoAttributesFragment = Pick<
  TireInsuranceInfo,
  'beelineCode' | 'itemName' | 'amount'
>

export type InsuranceInfoAttributesFragment = Pick<
  InsuranceInfo,
  | 'punctureRepair'
  | 'insurance'
  | 'paidInsurance'
  | 'paidInsuranceIncidentalExpenses'
  | 'cid'
  | 'insuranceJoinDate'
  | 'insurancePeriod'
  | 'insuranceShopName'
  | 'insuranceItemName'
  | 'insuranceTireSize'
>

export type CarListAttributesFragment = {
  carInfo: CarInfoAttributesFragment
  purchase?: Maybe<PurchaseAttributesFragment>
}

export type TireMakerAttributesFragment = Pick<
  TireMaker,
  'makerId' | 'makerName' | 'makerGroup' | 'alphaName' | 'kanaName' | 'abbName'
>

export type QuotationInfoAttributesFragment = Pick<
  QuotationInfo,
  | 'quatationId'
  | 'quatationMakeDate'
  | 'jan'
  | 'amount'
  | 'quantity'
  | 'makerItemCode'
  | 'beelineCode'
  | 'comCode'
  | 'makerId'
  | 'makerName'
  | 'itemName'
  | 'size'
  | 'tireLi'
  | 'tireSr'
  | 'tireXl'
  | 'tireRunFlat'
  | 'allSeasonsFlg'
  | 'wtrFlg'
  | 'efficientSpec'
  | 'wetSpec'
  | 'miniCarFlg'
  | 'minivanFlg'
  | 'suvFlg'
  | 'truckFlg'
  | 'importItem'
  | 'description'
  | 'brandAlphaName'
  | 'brandKanaName'
  | 'patternAlphaName'
  | 'patternKanaName'
  | 'sale1'
  | 'sale2'
  | 'sale3'
  | 'sale4'
  | 'sale5'
  | 'sale6'
  | 'sale7'
  | 'sale8'
  | 'tireImage'
> & {
  incidentalExpenses?: Maybe<
    Array<IncidentalExpensesOfQuotationAttributesFragment>
  >
}

export type IncidentalExpensesOfQuotationAttributesFragment = Pick<
  IncidentalExpensesOfQuotation,
  'itemCode' | 'itemName' | 'quantity' | 'amount'
>

export type IncidentalExpensesAttributesFragment = Pick<
  IncidentalExpenses,
  'jan' | 'beelineCode' | 'itemName' | 'amount'
>

export type TireInfoAttributesFragment = Pick<
  TireInfo,
  | 'jan'
  | 'makerItemCode'
  | 'beelineCode'
  | 'comCode'
  | 'makerId'
  | 'makerName'
  | 'itemName'
  | 'size'
  | 'tireLi'
  | 'tireSr'
  | 'tireXl'
  | 'tireRunFlat'
  | 'allSeasonsFlg'
  | 'wtrFlg'
  | 'efficientSpec'
  | 'wetSpec'
  | 'miniCarFlg'
  | 'minivanFlg'
  | 'suvFlg'
  | 'truckFlg'
  | 'importItem'
  | 'description'
  | 'brandAlphaName'
  | 'brandKanaName'
  | 'patternAlphaName'
  | 'patternKanaName'
  | 'sale1'
  | 'sale2'
  | 'sale3'
  | 'sale4'
  | 'sale5'
  | 'sale6'
  | 'sale7'
  | 'sale8'
  | 'tireImage'
  | 'shopStock'
  | 'warehouseStock'
> & {
  incidentalExpensesList: Array<IncidentalExpensesAttributesFragment>
  insuranceInfo?: Maybe<TireInsuranceInfoAttributesFragment>
}

export type TireSizeInfoAttributesFragment = Pick<
  TireSizeInfo,
  'tireWidthList' | 'obtList' | 'inchList'
>

export type StoreInfoAttributesFragment = Pick<
  StoreInfo,
  | 'shopId'
  | 'shopName'
  | 'shopNameAbb'
  | 'prefecture'
  | 'prefectureId'
  | 'tel'
  | 'fax'
  | 'post'
  | 'address'
  | 'openingHours'
  | 'receiptComment'
  | 'url'
  | 'linePitFlg'
>

export type MarkListResultAttributesFragment = Pick<
  MarkList,
  'groupName' | 'groupId' | 'markName' | 'markId' | 'check'
>

export type CustomerScheduleInfoAttributesFragment = Pick<
  CustomerScheduleInfo,
  | 'scheduleId'
  | 'shopId'
  | 'date'
  | 'startTime'
  | 'itemName'
  | 'quantity'
  | 'carName'
  | 'lineId'
  | 'jan'
  | 'workingTime'
  | 'workingId'
>

export type WorkingNameInfoAttributesFragment = Pick<
  WorkingNameInfo,
  | 'workingId'
  | 'workingName'
  | 'workingTime'
  | 'needTireEstimate'
  | 'bringingIn'
>

export type ScheduleInfoAttributesFragment = Pick<ScheduleInfo, 'date'>

export type TimeListAttributesFragment = Pick<
  TimeList,
  'startTime' | 'scheduleKind'
>

export type ScheduleResultAttributesFragment = Pick<
  ScheduleResult,
  'resultKind'
>

export type VehicleMakerAttributesFragment = Pick<
  VehicleMaker,
  'vehicleMakerId' | 'vehicleMakerName'
>

export type RegisterUserMutationVariables = Exact<{
  input: RegisterUserInput
}>

export type RegisterUserMutation = {
  registerUser: RegisterUserResultAttributesFragment
}

export type VerifyUserQrTokenMutationVariables = Exact<{
  input: VerifyUserQrTokenInput
}>

export type VerifyUserQrTokenMutation = {
  verifyUserQRToken: CustomerInfoAttributesFragment
}

export type IntegrateUserMutationVariables = Exact<{
  input: IntegrateUserInput
}>

export type IntegrateUserMutation = {
  integrateUser: IntegrateUserResultAttributesFragment
}

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput
}>

export type UpdateUserMutation = {
  updateUser: RegisterUserResultAttributesFragment
}

export type DeleteVehicleMutationVariables = Exact<{
  input: DeleteVehicleInput
}>

export type DeleteVehicleMutation = {
  deleteVehicle: DeleteVehicleResultAttributesFragment
}

export type CreateVehicleMutationVariables = Exact<{
  input: CreateVehicleInput
}>

export type CreateVehicleMutation = {
  createVehicle: CreateVehicleResultAttributesFragment
}

export type DeleteQuotationMutationVariables = Exact<{
  input: DeleteQuotationInput
}>

export type DeleteQuotationMutation = {
  deleteQuotation: DeleteQuotationResultAttributesFragment
}

export type CreateQuotationMutationVariables = Exact<{
  input: CreateQuotationInput
}>

export type CreateQuotationMutation = {
  createQuotation: CreateQuotationResultAttributesFragment
}

export type UnsubscribeMutationVariables = Exact<{ [key: string]: never }>

export type UnsubscribeMutation = {
  unsubscribe: UnsubscribeResultAttributesFragment
}

export type CreateEnqueteAnswerMutationVariables = Exact<{
  input: CreateEnqueteAnswerInput
}>

export type CreateEnqueteAnswerMutation = {
  createEnqueteAnswer: CreateEnqueteAnswerResultAttributesFragment
}

export type AuthenticateMutationVariables = Exact<{
  input: LoginInput
}>

export type AuthenticateMutation = {
  authenticate: AuthResultAttributesFragment
}

export type PutLogMutationVariables = Exact<{
  input: PutLogInput
}>

export type PutLogMutation = { putLog: Pick<PutLogResult, 'result'> }

export type UpdateScheduleMutationVariables = Exact<{
  input: UpdateScheduleInput
}>

export type UpdateScheduleMutation = {
  updateSchedule: ScheduleResultAttributesFragment
}

export type DeleteScheduleMutationVariables = Exact<{
  input: DeleteScheduleInput
}>

export type DeleteScheduleMutation = {
  deleteSchedule: ScheduleResultAttributesFragment
}

export type RegistScheduleMutationVariables = Exact<{
  input: RegistScheduleInput
}>

export type RegistScheduleMutation = {
  registSchedule: Pick<ScheduleResult, 'resultKind'>
}

export type UserQueryVariables = Exact<{ [key: string]: never }>

export type UserQuery = { user: CustomerInfoAttributesFragment }

export type VehiclesQueryVariables = Exact<{ [key: string]: never }>

export type VehiclesQuery = { vehicles: Array<CarListAttributesFragment> }

export type VehicleMakersQueryVariables = Exact<{ [key: string]: never }>

export type VehicleMakersQuery = {
  vehicleMakers: Array<VehicleMakerAttributesFragment>
}

export type VehicleDetailsQueryVariables = Exact<{
  input: VehicleDetailsInput
}>

export type VehicleDetailsQuery = { vehicleDetails: CarListAttributesFragment }

export type VehicleTypesQueryVariables = Exact<{
  input: VehicleTypesInput
}>

export type VehicleTypesQuery = {
  vehicleTypes: Array<VehicleTypeResultAttributesFragment>
}

export type TiresQueryVariables = Exact<{
  input: TiresInput
}>

export type TiresQuery = { tires: Array<TireInfoAttributesFragment> }

export type TireSizesQueryVariables = Exact<{
  input: TireSizesInput
}>

export type TireSizesQuery = { tireSizes: TireSizeInfoAttributesFragment }

export type TireMakersQueryVariables = Exact<{ [key: string]: never }>

export type TireMakersQuery = { tireMakers: Array<TireMakerAttributesFragment> }

export type QuotationsQueryVariables = Exact<{ [key: string]: never }>

export type QuotationsQuery = {
  quotations: Array<QuotationInfoAttributesFragment>
}

export type QuotationDetailsQueryVariables = Exact<{
  input: QuotationDetailsInput
}>

export type QuotationDetailsQuery = {
  quotationDetails: QuotationInfoAttributesFragment
}

export type PurchasesQueryVariables = Exact<{
  input: PurchasesInput
}>

export type PurchasesQuery = { purchases: Array<PurchaseAttributesFragment> }

export type PurchaseDetailsQueryVariables = Exact<{
  input: PurchaseDetailsInput
}>

export type PurchaseDetailsQuery = {
  purchaseDetails: PurchaseDetailsResultAttributesFragment
}

export type StoresQueryVariables = Exact<{
  input?: Maybe<StoresInput>
}>

export type StoresQuery = { stores: Array<StoreInfoAttributesFragment> }

export type PostCodeQueryVariables = Exact<{
  input: PostCodeInput
}>

export type PostCodeQuery = { postCode: PostCodeResultAttributesFragment }

export type VehicleTypesByMakerQueryVariables = Exact<{
  input: VehicleTypesByMakerInput
}>

export type VehicleTypesByMakerQuery = {
  vehicleTypesByMaker: Array<VehicleTypeResultAttributesFragment>
}

export type VehiclePeriodsQueryVariables = Exact<{
  input: VehiclePeriodsInput
}>

export type VehiclePeriodsQuery = {
  vehiclePeriods: Array<VehiclePeriodsResultAttributesFragment>
}

export type MarkListsQueryVariables = Exact<{
  input: MarkListsInput
}>

export type MarkListsQuery = {
  markLists: Array<MarkListResultAttributesFragment>
}

export type PrefectureQueryVariables = Exact<{
  input: PrefectureInput
}>

export type PrefectureQuery = { prefecture: PrefectureResultAttributesFragment }

export type IsEstimateQueryVariables = Exact<{
  input: IsEstimateInput
}>

export type IsEstimateQuery = { isEstimate: IsEstimateResultAttributesFragment }

export type SearchCustomerSchedulesQueryVariables = Exact<{
  input: SearchCustomerScheduleInput
}>

export type SearchCustomerSchedulesQuery = {
  searchCustomerSchedules: Array<CustomerScheduleInfoAttributesFragment>
}

export type WorkingNamesQueryVariables = Exact<{
  input: WorkingNamesInput
}>

export type WorkingNamesQuery = {
  workingNames: Array<WorkingNameInfoAttributesFragment>
}

export type SchedulesQueryVariables = Exact<{
  input: SchedulesInput
}>

export type SchedulesQuery = {
  schedules: Array<
    {
      timeList?: Maybe<Array<TimeListAttributesFragment>>
    } & ScheduleInfoAttributesFragment
  >
}

export const RegisterUserResultAttributesFragmentDoc = gql`
  fragment RegisterUserResultAttributes on RegisterUserResult {
    customerId
    lineId
  }
`
export const CustomerInfoAttributesFragmentDoc = gql`
  fragment CustomerInfoAttributes on CustomerInfo {
    customerId
    customerKind
    corpName
    corpNameKana
    lastName
    firstName
    lastNameKana
    firstNameKana
    postCode
    address
    prefectureName
    cityName
    areaName
    buildingName
    tel
    mailAddress
    birthday
    sex
    shopId
    shopName
    lineId
  }
`
export const IntegrateUserResultAttributesFragmentDoc = gql`
  fragment IntegrateUserResultAttributes on IntegrateUserResult {
    result
  }
`
export const DeleteVehicleResultAttributesFragmentDoc = gql`
  fragment DeleteVehicleResultAttributes on DeleteVehicleResult {
    carId
  }
`
export const CreateVehicleResultAttributesFragmentDoc = gql`
  fragment CreateVehicleResultAttributes on CreateVehicleResult {
    carId
  }
`
export const VehicleTypeResultAttributesFragmentDoc = gql`
  fragment VehicleTypeResultAttributes on VehicleType {
    vehicleMakerName
    vehicleTypeName
  }
`
export const DeleteQuotationResultAttributesFragmentDoc = gql`
  fragment DeleteQuotationResultAttributes on DeleteQuotationResult {
    result
  }
`
export const CreateQuotationResultAttributesFragmentDoc = gql`
  fragment CreateQuotationResultAttributes on CreateQuotationResult {
    quatationId
  }
`
export const UnsubscribeResultAttributesFragmentDoc = gql`
  fragment UnsubscribeResultAttributes on UnsubscribeResult {
    result
  }
`
export const CreateEnqueteAnswerResultAttributesFragmentDoc = gql`
  fragment CreateEnqueteAnswerResultAttributes on CreateEnqueteAnswerResult {
    answerId
  }
`
export const UserAttributesFragmentDoc = gql`
  fragment UserAttributes on User {
    lineId
    userNumber
  }
`
export const AuthResultAttributesFragmentDoc = gql`
  fragment AuthResultAttributes on AuthResult {
    accessToken
    user {
      ...UserAttributes
    }
  }
  ${UserAttributesFragmentDoc}
`
export const PurchaseItemAttributesFragmentDoc = gql`
  fragment PurchaseItemAttributes on PurchaseItem {
    productName
    isOld
    makerName
    size
    quantity
    amount
    cost
    jan
    beelineCode
    tireSize
    flatRate
    tireInch
  }
`
export const InsuranceInfoAttributesFragmentDoc = gql`
  fragment InsuranceInfoAttributes on InsuranceInfo {
    punctureRepair
    insurance
    paidInsurance
    paidInsuranceIncidentalExpenses
    cid
    insuranceJoinDate
    insurancePeriod
    insuranceShopName
    insuranceItemName
    insuranceTireSize
  }
`
export const PurchaseAttributesFragmentDoc = gql`
  fragment PurchaseAttributes on Purchase {
    slipId
    purchaseDate
    shopName
    purchaseUserName
    regiUserName
    amount
    purchaseItemList {
      ...PurchaseItemAttributes
    }
    insuranceInfo {
      ...InsuranceInfoAttributes
    }
  }
  ${PurchaseItemAttributesFragmentDoc}
  ${InsuranceInfoAttributesFragmentDoc}
`
export const PurchaseDetailsResultAttributesFragmentDoc = gql`
  fragment PurchaseDetailsResultAttributes on PurchaseDetailsResult {
    purchaseInfo {
      ...PurchaseAttributes
    }
  }
  ${PurchaseAttributesFragmentDoc}
`
export const PostCodeResultAttributesFragmentDoc = gql`
  fragment PostCodeResultAttributes on PostCodeResult {
    pref
    city
    town
  }
`
export const VehicleTireSizeResultAttributesFragmentDoc = gql`
  fragment VehicleTireSizeResultAttributes on VehicleTireSize {
    size
    tireSize
    flatRate
    tireInch
  }
`
export const VehiclePeriodsResultAttributesFragmentDoc = gql`
  fragment VehiclePeriodsResultAttributes on VehicleInfo {
    vehicleMakerName
    vehicleTypeName
    vehiclePeriodFrom
    vehiclePeriodTo
    vehicleModelName
    vehicleTireSizeList {
      ...VehicleTireSizeResultAttributes
    }
  }
  ${VehicleTireSizeResultAttributesFragmentDoc}
`
export const PrefectureResultAttributesFragmentDoc = gql`
  fragment PrefectureResultAttributes on PrefectureResult {
    prefecture
  }
`
export const IsEstimateResultAttributesFragmentDoc = gql`
  fragment IsEstimateResultAttributes on IsEstimateResult {
    isEstimate
  }
`
export const CarInfoAttributesFragmentDoc = gql`
  fragment CarInfoAttributes on CarInfo {
    carId
    carName
    vehicleMakerName
    vehicleTypeName
    vehicleModel
    periodFrom
    periodTo
    vehicleGrade
    numberPlateArea
    numberPlateClass
    numberPlateHira
    numberPlateSeries
    firstYearRegistration
    mileage
    tireUpdateDate
    tireRotationDate
    inspection
    note
  }
`
export const CarListAttributesFragmentDoc = gql`
  fragment CarListAttributes on CarList {
    carInfo {
      ...CarInfoAttributes
    }
    purchase {
      ...PurchaseAttributes
    }
  }
  ${CarInfoAttributesFragmentDoc}
  ${PurchaseAttributesFragmentDoc}
`
export const TireMakerAttributesFragmentDoc = gql`
  fragment TireMakerAttributes on TireMaker {
    makerId
    makerName
    makerGroup
    alphaName
    kanaName
    abbName
  }
`
export const IncidentalExpensesOfQuotationAttributesFragmentDoc = gql`
  fragment IncidentalExpensesOfQuotationAttributes on IncidentalExpensesOfQuotation {
    itemCode
    itemName
    quantity
    amount
  }
`
export const QuotationInfoAttributesFragmentDoc = gql`
  fragment QuotationInfoAttributes on QuotationInfo {
    quatationId
    quatationMakeDate
    jan
    amount
    quantity
    makerItemCode
    beelineCode
    comCode
    makerId
    makerName
    itemName
    size
    tireLi
    tireSr
    tireXl
    tireRunFlat
    allSeasonsFlg
    wtrFlg
    efficientSpec
    wetSpec
    miniCarFlg
    minivanFlg
    suvFlg
    truckFlg
    importItem
    description
    brandAlphaName
    brandKanaName
    patternAlphaName
    patternKanaName
    sale1
    sale2
    sale3
    sale4
    sale5
    sale6
    sale7
    sale8
    tireImage
    incidentalExpenses {
      ...IncidentalExpensesOfQuotationAttributes
    }
  }
  ${IncidentalExpensesOfQuotationAttributesFragmentDoc}
`
export const IncidentalExpensesAttributesFragmentDoc = gql`
  fragment IncidentalExpensesAttributes on IncidentalExpenses {
    jan
    beelineCode
    itemName
    amount
  }
`
export const TireInsuranceInfoAttributesFragmentDoc = gql`
  fragment TireInsuranceInfoAttributes on TireInsuranceInfo {
    beelineCode
    itemName
    amount
  }
`
export const TireInfoAttributesFragmentDoc = gql`
  fragment TireInfoAttributes on TireInfo {
    jan
    makerItemCode
    beelineCode
    comCode
    makerId
    makerName
    itemName
    size
    tireLi
    tireSr
    tireXl
    tireRunFlat
    allSeasonsFlg
    wtrFlg
    efficientSpec
    wetSpec
    miniCarFlg
    minivanFlg
    suvFlg
    truckFlg
    importItem
    description
    brandAlphaName
    brandKanaName
    patternAlphaName
    patternKanaName
    sale1
    sale2
    sale3
    sale4
    sale5
    sale6
    sale7
    sale8
    tireImage
    shopStock
    warehouseStock
    incidentalExpensesList {
      ...IncidentalExpensesAttributes
    }
    insuranceInfo {
      ...TireInsuranceInfoAttributes
    }
  }
  ${IncidentalExpensesAttributesFragmentDoc}
  ${TireInsuranceInfoAttributesFragmentDoc}
`
export const TireSizeInfoAttributesFragmentDoc = gql`
  fragment TireSizeInfoAttributes on TireSizeInfo {
    tireWidthList
    obtList
    inchList
  }
`
export const StoreInfoAttributesFragmentDoc = gql`
  fragment StoreInfoAttributes on StoreInfo {
    shopId
    shopName
    shopNameAbb
    prefecture
    prefectureId
    tel
    fax
    post
    address
    openingHours
    receiptComment
    url
    linePitFlg
  }
`
export const MarkListResultAttributesFragmentDoc = gql`
  fragment MarkListResultAttributes on MarkList {
    groupName
    groupId
    markName
    markId
    check
  }
`
export const CustomerScheduleInfoAttributesFragmentDoc = gql`
  fragment CustomerScheduleInfoAttributes on CustomerScheduleInfo {
    scheduleId
    shopId
    date
    startTime
    itemName
    quantity
    carName
    lineId
    jan
    workingTime
    workingId
  }
`
export const WorkingNameInfoAttributesFragmentDoc = gql`
  fragment WorkingNameInfoAttributes on WorkingNameInfo {
    workingId
    workingName
    workingTime
    needTireEstimate
    bringingIn
  }
`
export const ScheduleInfoAttributesFragmentDoc = gql`
  fragment ScheduleInfoAttributes on ScheduleInfo {
    date
  }
`
export const TimeListAttributesFragmentDoc = gql`
  fragment TimeListAttributes on TimeList {
    startTime
    scheduleKind
  }
`
export const ScheduleResultAttributesFragmentDoc = gql`
  fragment ScheduleResultAttributes on ScheduleResult {
    resultKind
  }
`
export const VehicleMakerAttributesFragmentDoc = gql`
  fragment VehicleMakerAttributes on VehicleMaker {
    vehicleMakerId
    vehicleMakerName
  }
`
export const RegisterUserDocument = gql`
  mutation registerUser($input: RegisterUserInput!) {
    registerUser(input: $input) {
      ...RegisterUserResultAttributes
    }
  }
  ${RegisterUserResultAttributesFragmentDoc}
`
export const VerifyUserQrTokenDocument = gql`
  mutation verifyUserQRToken($input: VerifyUserQRTokenInput!) {
    verifyUserQRToken(input: $input) {
      ...CustomerInfoAttributes
    }
  }
  ${CustomerInfoAttributesFragmentDoc}
`
export const IntegrateUserDocument = gql`
  mutation integrateUser($input: IntegrateUserInput!) {
    integrateUser(input: $input) {
      ...IntegrateUserResultAttributes
    }
  }
  ${IntegrateUserResultAttributesFragmentDoc}
`
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      ...RegisterUserResultAttributes
    }
  }
  ${RegisterUserResultAttributesFragmentDoc}
`
export const DeleteVehicleDocument = gql`
  mutation deleteVehicle($input: DeleteVehicleInput!) {
    deleteVehicle(input: $input) {
      ...DeleteVehicleResultAttributes
    }
  }
  ${DeleteVehicleResultAttributesFragmentDoc}
`
export const CreateVehicleDocument = gql`
  mutation createVehicle($input: CreateVehicleInput!) {
    createVehicle(input: $input) {
      ...CreateVehicleResultAttributes
    }
  }
  ${CreateVehicleResultAttributesFragmentDoc}
`
export const DeleteQuotationDocument = gql`
  mutation deleteQuotation($input: DeleteQuotationInput!) {
    deleteQuotation(input: $input) {
      ...DeleteQuotationResultAttributes
    }
  }
  ${DeleteQuotationResultAttributesFragmentDoc}
`
export const CreateQuotationDocument = gql`
  mutation createQuotation($input: CreateQuotationInput!) {
    createQuotation(input: $input) {
      ...CreateQuotationResultAttributes
    }
  }
  ${CreateQuotationResultAttributesFragmentDoc}
`
export const UnsubscribeDocument = gql`
  mutation unsubscribe {
    unsubscribe {
      ...UnsubscribeResultAttributes
    }
  }
  ${UnsubscribeResultAttributesFragmentDoc}
`
export const CreateEnqueteAnswerDocument = gql`
  mutation createEnqueteAnswer($input: CreateEnqueteAnswerInput!) {
    createEnqueteAnswer(input: $input) {
      ...CreateEnqueteAnswerResultAttributes
    }
  }
  ${CreateEnqueteAnswerResultAttributesFragmentDoc}
`
export const AuthenticateDocument = gql`
  mutation authenticate($input: LoginInput!) {
    authenticate(input: $input) {
      ...AuthResultAttributes
    }
  }
  ${AuthResultAttributesFragmentDoc}
`
export const PutLogDocument = gql`
  mutation putLog($input: PutLogInput!) {
    putLog(input: $input) {
      result
    }
  }
`
export const UpdateScheduleDocument = gql`
  mutation updateSchedule($input: UpdateScheduleInput!) {
    updateSchedule(input: $input) {
      ...ScheduleResultAttributes
    }
  }
  ${ScheduleResultAttributesFragmentDoc}
`
export const DeleteScheduleDocument = gql`
  mutation deleteSchedule($input: DeleteScheduleInput!) {
    deleteSchedule(input: $input) {
      ...ScheduleResultAttributes
    }
  }
  ${ScheduleResultAttributesFragmentDoc}
`
export const RegistScheduleDocument = gql`
  mutation registSchedule($input: RegistScheduleInput!) {
    registSchedule(input: $input) {
      resultKind
    }
  }
`
export const UserDocument = gql`
  query user {
    user {
      ...CustomerInfoAttributes
    }
  }
  ${CustomerInfoAttributesFragmentDoc}
`
export const VehiclesDocument = gql`
  query vehicles {
    vehicles {
      ...CarListAttributes
    }
  }
  ${CarListAttributesFragmentDoc}
`
export const VehicleMakersDocument = gql`
  query vehicleMakers {
    vehicleMakers {
      ...VehicleMakerAttributes
    }
  }
  ${VehicleMakerAttributesFragmentDoc}
`
export const VehicleDetailsDocument = gql`
  query vehicleDetails($input: VehicleDetailsInput!) {
    vehicleDetails(input: $input) {
      ...CarListAttributes
    }
  }
  ${CarListAttributesFragmentDoc}
`
export const VehicleTypesDocument = gql`
  query vehicleTypes($input: VehicleTypesInput!) {
    vehicleTypes(input: $input) {
      ...VehicleTypeResultAttributes
    }
  }
  ${VehicleTypeResultAttributesFragmentDoc}
`
export const TiresDocument = gql`
  query tires($input: TiresInput!) {
    tires(input: $input) {
      ...TireInfoAttributes
    }
  }
  ${TireInfoAttributesFragmentDoc}
`
export const TireSizesDocument = gql`
  query tireSizes($input: TireSizesInput!) {
    tireSizes(input: $input) {
      ...TireSizeInfoAttributes
    }
  }
  ${TireSizeInfoAttributesFragmentDoc}
`
export const TireMakersDocument = gql`
  query tireMakers {
    tireMakers {
      ...TireMakerAttributes
    }
  }
  ${TireMakerAttributesFragmentDoc}
`
export const QuotationsDocument = gql`
  query quotations {
    quotations {
      ...QuotationInfoAttributes
    }
  }
  ${QuotationInfoAttributesFragmentDoc}
`
export const QuotationDetailsDocument = gql`
  query quotationDetails($input: QuotationDetailsInput!) {
    quotationDetails(input: $input) {
      ...QuotationInfoAttributes
    }
  }
  ${QuotationInfoAttributesFragmentDoc}
`
export const PurchasesDocument = gql`
  query purchases($input: PurchasesInput!) {
    purchases(input: $input) {
      ...PurchaseAttributes
    }
  }
  ${PurchaseAttributesFragmentDoc}
`
export const PurchaseDetailsDocument = gql`
  query purchaseDetails($input: PurchaseDetailsInput!) {
    purchaseDetails(input: $input) {
      ...PurchaseDetailsResultAttributes
    }
  }
  ${PurchaseDetailsResultAttributesFragmentDoc}
`
export const StoresDocument = gql`
  query stores($input: StoresInput) {
    stores(input: $input) {
      ...StoreInfoAttributes
    }
  }
  ${StoreInfoAttributesFragmentDoc}
`
export const PostCodeDocument = gql`
  query postCode($input: PostCodeInput!) {
    postCode(input: $input) {
      ...PostCodeResultAttributes
    }
  }
  ${PostCodeResultAttributesFragmentDoc}
`
export const VehicleTypesByMakerDocument = gql`
  query vehicleTypesByMaker($input: VehicleTypesByMakerInput!) {
    vehicleTypesByMaker(input: $input) {
      ...VehicleTypeResultAttributes
    }
  }
  ${VehicleTypeResultAttributesFragmentDoc}
`
export const VehiclePeriodsDocument = gql`
  query vehiclePeriods($input: VehiclePeriodsInput!) {
    vehiclePeriods(input: $input) {
      ...VehiclePeriodsResultAttributes
    }
  }
  ${VehiclePeriodsResultAttributesFragmentDoc}
`
export const MarkListsDocument = gql`
  query markLists($input: MarkListsInput!) {
    markLists(input: $input) {
      ...MarkListResultAttributes
    }
  }
  ${MarkListResultAttributesFragmentDoc}
`
export const PrefectureDocument = gql`
  query prefecture($input: PrefectureInput!) {
    prefecture(input: $input) {
      ...PrefectureResultAttributes
    }
  }
  ${PrefectureResultAttributesFragmentDoc}
`
export const IsEstimateDocument = gql`
  query isEstimate($input: IsEstimateInput!) {
    isEstimate(input: $input) {
      ...IsEstimateResultAttributes
    }
  }
  ${IsEstimateResultAttributesFragmentDoc}
`
export const SearchCustomerSchedulesDocument = gql`
  query searchCustomerSchedules($input: SearchCustomerScheduleInput!) {
    searchCustomerSchedules(input: $input) {
      ...CustomerScheduleInfoAttributes
    }
  }
  ${CustomerScheduleInfoAttributesFragmentDoc}
`
export const WorkingNamesDocument = gql`
  query workingNames($input: WorkingNamesInput!) {
    workingNames(input: $input) {
      ...WorkingNameInfoAttributes
    }
  }
  ${WorkingNameInfoAttributesFragmentDoc}
`
export const SchedulesDocument = gql`
  query schedules($input: SchedulesInput!) {
    schedules(input: $input) {
      ...ScheduleInfoAttributes
      timeList {
        ...TimeListAttributes
      }
    }
  }
  ${ScheduleInfoAttributesFragmentDoc}
  ${TimeListAttributesFragmentDoc}
`

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string
) => Promise<T>

const defaultWrapper: SdkFunctionWrapper = (action, _operationName) => action()

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper
) {
  return {
    registerUser(
      variables: RegisterUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RegisterUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegisterUserMutation>(
            RegisterUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'registerUser'
      )
    },
    verifyUserQRToken(
      variables: VerifyUserQrTokenMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VerifyUserQrTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VerifyUserQrTokenMutation>(
            VerifyUserQrTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'verifyUserQRToken'
      )
    },
    integrateUser(
      variables: IntegrateUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<IntegrateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IntegrateUserMutation>(
            IntegrateUserDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'integrateUser'
      )
    },
    updateUser(
      variables: UpdateUserMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'updateUser'
      )
    },
    deleteVehicle(
      variables: DeleteVehicleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteVehicleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteVehicleMutation>(
            DeleteVehicleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteVehicle'
      )
    },
    createVehicle(
      variables: CreateVehicleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateVehicleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateVehicleMutation>(
            CreateVehicleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createVehicle'
      )
    },
    deleteQuotation(
      variables: DeleteQuotationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteQuotationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteQuotationMutation>(
            DeleteQuotationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteQuotation'
      )
    },
    createQuotation(
      variables: CreateQuotationMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateQuotationMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateQuotationMutation>(
            CreateQuotationDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createQuotation'
      )
    },
    unsubscribe(
      variables?: UnsubscribeMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UnsubscribeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UnsubscribeMutation>(UnsubscribeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'unsubscribe'
      )
    },
    createEnqueteAnswer(
      variables: CreateEnqueteAnswerMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<CreateEnqueteAnswerMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateEnqueteAnswerMutation>(
            CreateEnqueteAnswerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'createEnqueteAnswer'
      )
    },
    authenticate(
      variables: AuthenticateMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<AuthenticateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AuthenticateMutation>(
            AuthenticateDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'authenticate'
      )
    },
    putLog(
      variables: PutLogMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PutLogMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PutLogMutation>(PutLogDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'putLog'
      )
    },
    updateSchedule(
      variables: UpdateScheduleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UpdateScheduleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateScheduleMutation>(
            UpdateScheduleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'updateSchedule'
      )
    },
    deleteSchedule(
      variables: DeleteScheduleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<DeleteScheduleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteScheduleMutation>(
            DeleteScheduleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'deleteSchedule'
      )
    },
    registSchedule(
      variables: RegistScheduleMutationVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<RegistScheduleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RegistScheduleMutation>(
            RegistScheduleDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'registSchedule'
      )
    },
    user(
      variables?: UserQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<UserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UserQuery>(UserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'user'
      )
    },
    vehicles(
      variables?: VehiclesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehiclesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehiclesQuery>(VehiclesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'vehicles'
      )
    },
    vehicleMakers(
      variables?: VehicleMakersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehicleMakersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehicleMakersQuery>(VehicleMakersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'vehicleMakers'
      )
    },
    vehicleDetails(
      variables: VehicleDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehicleDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehicleDetailsQuery>(
            VehicleDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'vehicleDetails'
      )
    },
    vehicleTypes(
      variables: VehicleTypesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehicleTypesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehicleTypesQuery>(VehicleTypesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'vehicleTypes'
      )
    },
    tires(
      variables: TiresQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TiresQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TiresQuery>(TiresDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tires'
      )
    },
    tireSizes(
      variables: TireSizesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TireSizesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TireSizesQuery>(TireSizesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tireSizes'
      )
    },
    tireMakers(
      variables?: TireMakersQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<TireMakersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TireMakersQuery>(TireMakersDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'tireMakers'
      )
    },
    quotations(
      variables?: QuotationsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<QuotationsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<QuotationsQuery>(QuotationsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'quotations'
      )
    },
    quotationDetails(
      variables: QuotationDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<QuotationDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<QuotationDetailsQuery>(
            QuotationDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'quotationDetails'
      )
    },
    purchases(
      variables: PurchasesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PurchasesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PurchasesQuery>(PurchasesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'purchases'
      )
    },
    purchaseDetails(
      variables: PurchaseDetailsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PurchaseDetailsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PurchaseDetailsQuery>(
            PurchaseDetailsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'purchaseDetails'
      )
    },
    stores(
      variables?: StoresQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<StoresQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<StoresQuery>(StoresDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'stores'
      )
    },
    postCode(
      variables: PostCodeQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PostCodeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PostCodeQuery>(PostCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'postCode'
      )
    },
    vehicleTypesByMaker(
      variables: VehicleTypesByMakerQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehicleTypesByMakerQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehicleTypesByMakerQuery>(
            VehicleTypesByMakerDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'vehicleTypesByMaker'
      )
    },
    vehiclePeriods(
      variables: VehiclePeriodsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<VehiclePeriodsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<VehiclePeriodsQuery>(
            VehiclePeriodsDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'vehiclePeriods'
      )
    },
    markLists(
      variables: MarkListsQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<MarkListsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<MarkListsQuery>(MarkListsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'markLists'
      )
    },
    prefecture(
      variables: PrefectureQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<PrefectureQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<PrefectureQuery>(PrefectureDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'prefecture'
      )
    },
    isEstimate(
      variables: IsEstimateQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<IsEstimateQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<IsEstimateQuery>(IsEstimateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'isEstimate'
      )
    },
    searchCustomerSchedules(
      variables: SearchCustomerSchedulesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SearchCustomerSchedulesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SearchCustomerSchedulesQuery>(
            SearchCustomerSchedulesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders }
          ),
        'searchCustomerSchedules'
      )
    },
    workingNames(
      variables: WorkingNamesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<WorkingNamesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<WorkingNamesQuery>(WorkingNamesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'workingNames'
      )
    },
    schedules(
      variables: SchedulesQueryVariables,
      requestHeaders?: Dom.RequestInit['headers']
    ): Promise<SchedulesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SchedulesQuery>(SchedulesDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        'schedules'
      )
    },
  }
}
export type Sdk = ReturnType<typeof getSdk>
